import { WIX_FORUM } from '@wix/app-definition-ids';
import { SearchDocumentType } from '@wix/client-search-sdk';
import { IndexableApp } from '../types';

export const ForumApp: IndexableApp = {
  documentType: SearchDocumentType.Forums,
  appDefId: WIX_FORUM,
  translations: {
    tabLabel: 'searchResults.tabs.label.forums',
    tabLabelWithCount: 'searchResults.tabs.label.forums.withCount',
    tabAccessibilityLabel: 'searchResults.tabs.label.forums.accessibilityLabel',
  },
};
