import { WIX_NEW_STORES } from '@wix/app-definition-ids';
import { SearchDocumentType } from '@wix/client-search-sdk';
import { IndexableApp } from '../types';

export const StoresApp: IndexableApp = {
  documentType: SearchDocumentType.Products,
  appDefId: WIX_NEW_STORES,
  translations: {
    tabLabel: 'searchResults.tabs.label.products',
    tabLabelWithCount: 'searchResults.tabs.label.products.withCount',
    tabAccessibilityLabel:
      'searchResults.tabs.label.products.accessibilityLabel',
  },
};
