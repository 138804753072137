import { SearchDocumentType } from '@wix/client-search-sdk';
import { IndexableApp } from '../types';

export const AllApp: IndexableApp = {
  documentType: SearchDocumentType.All,
  translations: {
    tabLabel: 'searchResults.tabs.label.all',
    tabLabelWithCount: 'searchResults.tabs.label.all.withCount',
    tabAccessibilityLabel: 'searchResults.tabs.label.all.accessibilityLabel',
  },
};
