import React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import {
  SampleLayoutProps,
  ListLayoutItemProps,
  OnDocumentClick,
} from '../Layout.types';
import { Title } from '../Title';
import { LayoutItem } from '../LayoutItem.types';
import { ListLayoutItem } from '../ListLayout';
import { GridLayoutItem } from '../GridLayout';
import { ViewAllButton } from '../ViewAllButton';

import {
  LIST_LAYOUT_DEFAULT_THUMBNAIL_HEIGHT,
  LIST_LAYOUT_DEFAULT_THUMBNAIL_WIDTH,
} from '../ListItemThumbnail';

import { EventListItemRenderMode } from '../../../../../integrations/events/EventListItem';

import { st, classes } from './SampleLayout.st.css';
import {
  componentsMap,
  layoutData,
  LayoutType,
} from '../../../../../integrations/uiComponents';

const THUMBNAIL_WIDTH = {
  DESKTOP: 105,
  MOBILE: 105,
};

const THUMBNAIL_HEIGHT = {
  DESKTOP: 70,
  MOBILE: 70,
};

const getThumbnailWidth = (isMobile: boolean, isFluid: boolean) =>
  isFluid
    ? LIST_LAYOUT_DEFAULT_THUMBNAIL_WIDTH
    : isMobile
    ? THUMBNAIL_WIDTH.MOBILE
    : THUMBNAIL_WIDTH.DESKTOP;

const getThumbnailHeight = (isMobile: boolean, isFluid: boolean) =>
  isFluid
    ? LIST_LAYOUT_DEFAULT_THUMBNAIL_HEIGHT
    : isMobile
    ? THUMBNAIL_HEIGHT.MOBILE
    : THUMBNAIL_HEIGHT.DESKTOP;

type ISample = SampleLayoutProps['results'][0];

export const SampleLayout: React.FC<SampleLayoutProps> = (props) => {
  const { isEditorX, isMobile } = useEnvironment();

  const getRenderItemProps = (
    item: LayoutItem,
    index: number,
  ): ListLayoutItemProps & { key: any } => {
    const onLinkClick: OnDocumentClick = (e, clickOrigin) =>
      props.onItemLinkClick?.(e, item, index, clickOrigin);

    const thumbnailWidth = getThumbnailWidth(isMobile, isEditorX);
    const thumbnailHeight = getThumbnailHeight(isMobile, isEditorX);

    return {
      item,
      key: item.id,
      onLinkClick,
      thumbnailWidth,
      thumbnailHeight,
    };
  };

  const renderListLayoutItem = (item: LayoutItem, index: number) => {
    return (
      <ListLayoutItem
        {...getRenderItemProps(item, index)}
        thumbnailWidth={getThumbnailWidth(isMobile, isEditorX)}
        thumbnailHeight={getThumbnailHeight(isMobile, isEditorX)}
      />
    );
  };

  const renderGridLayoutItem = (item: LayoutItem, index: number) => {
    return (
      <GridLayoutItem
        {...getRenderItemProps(item, index)}
        renderItemExtention={(gridItem) => {
          if (props.renderItemExtention) {
            return props.renderItemExtention(gridItem);
          }
          return null;
        }}
      />
    );
  };

  const renderSampleGroup = (sample: ISample) => {
    const { onViewAllClick } = props;

    if (!sample.documents.length) {
      return;
    }

    const layoutType =
      layoutData[sample.documentType]?.layout || LayoutType.List;

    const Item = componentsMap[sample.documentType]?.Item;

    const renderMode = isEditorX
      ? EventListItemRenderMode.default
      : EventListItemRenderMode.condensed;

    //* Need to add aditional props to certain layout items
    const renderItem = Item
      ? (item: LayoutItem, index: number) => (
          // @ts-expect-error
          <Item {...getRenderItemProps(item, index)} renderMode={renderMode} />
        )
      : {
          [LayoutType.Grid]: renderGridLayoutItem,
          [LayoutType.List]: renderListLayoutItem,
        }[layoutType];

    const sampleGroupLabel = sample.documentType?.split('/').pop();

    return (
      <div
        data-hook={`sample-layout-group-${sampleGroupLabel}`}
        className={classes.group}
        key={sample.documentType}
      >
        <h2 className={classes.groupHeader}>
          <Title
            data-hook="sample-layout-group-title"
            title={sample.title}
            onClick={(e) => onViewAllClick(e, sample.documentType)}
            url={sample.url}
          />
        </h2>

        <ul
          data-hook="sample-layout-group-items"
          className={st(classes.groupItems, {
            layout: layoutType,
          })}
        >
          {sample.documents.map(renderItem)}
        </ul>
        <ViewAllButton
          url={sample.url}
          onClick={(e) => onViewAllClick(e, sample.documentType)}
          data-hook="sample-layout-group-view-all"
        />
      </div>
    );
  };

  return (
    <div
      className={st(classes.root, {
        fixed: !isEditorX,
        fluid: isEditorX,
        mobileView: isMobile,
      })}
      data-hook="sample-layout"
      tabIndex={-1}
      role="region"
      aria-label={props.label}
    >
      {props.results.map(renderSampleGroup)}
    </div>
  );
};
