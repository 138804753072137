import { Experiments } from '@wix/yoshi-flow-editor';
import { IndexableApp } from './types';

import { AllApp } from './all/app';
import { PagesApp } from './pages/app';
import { BlogApp } from './blog/app';
import { ForumApp } from './forum/app';
import { EventsApp } from './events/app';
import { ChallengesApp } from './challenges/app';
import { StoresApp } from './stores/app';
import { ProGalleryApp } from './proGallery/app';
import { BookingsApp } from './bookings/app';

const allSupportedApps: IndexableApp[] = [
  AllApp,
  PagesApp,
  BlogApp,
  BookingsApp,
  ChallengesApp,
  EventsApp,
  ForumApp,
  ProGalleryApp,
  StoresApp,
];

export const getSupportedApps = (experiments: Experiments): IndexableApp[] =>
  allSupportedApps.filter((app) => !app.spec || experiments.enabled(app.spec));
